import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { TreeViewColumn, TreeViewOptions } from '@/components/tree-view/TreeView';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { UserType } from '@/models/User';
import { Rpc } from '@/models/Rpc';
import { cloneDeep } from 'lodash';
import { TermijnBewakingInfo } from '@/support/Info';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';

@Component<TermijnBewaking>({
  components: {
    ReportTypeFilter,
  },
})
export default class TermijnBewaking extends AbstractMoreInfoDialog {
  public $pageTitle = 'Termijn bewaking';

  protected filters: TermijnBewakingFilters = {
    types: [],
    departments: [],
    actor: 'expert',
    breakdown: 'status_employee_report',
    workflows: [],
    opname_variants: [],
    management_info_classes: [],
  };

  protected savedFilters: {[id: string]: TermijnBewakingFilters} = {};

  protected moreInfo: MoreInfo[] = TermijnBewakingInfo;

  // Organizations
  protected organizations: Organization[] | null = null;

  protected activeOrganization = '';

  // user types
  protected userTypes: UserType[] = [
    {
      key: 'expert',
      name: 'Opnemer',
    },
    {
      key: 'second_expert',
      name: 'Deskundige (indien anders dan opnemer)',
    },
    {
      key: 'tc',
      name: 'Technisch Coordinator',
    },
  ];

  // breakdown
  protected breakdowns: { [key: string]: string }[] = [
    {
      name: 'Status, Actiehouder, Dossier',
      key: 'status_employee_report',
    },
    {
      name: 'Actiehouder, Status, Dossier',
      key: 'employee_status_report',
    },
    {
      name: 'Status, Dossier, Actiehouder',
      key: 'status_report_employee',
    },
  ];

  // loaders
  protected isLoading = false;

  protected items: {[id: string]: RealtimeManagementStruct[]} = {};

  protected columns: TreeViewColumn[] = [
    {
      key: 'first',
      name: '# Naam',
      style: 'min-width: 300px !important',
      visible: true,
      transform: (item: RealtimeManagementStruct) => {
        if (item.data?.is_special) {
          return `${item.name} <i data-v-a2b5d2cc="" aria-hidden="true" class="v-icon special-icon material-icons theme--light primary--text">star</i>`;
        }
        return item.name;
      },
    },
    {
      key: 'report_count',
      name: '# dossiers',
      class: 'text-xs-right',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'damages_count',
      name: '# Opgenomen schades (som)',
      class: 'text-xs-right',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'current_status',
      name: 'Dagen in status (max.)',
      class: 'text-xs-right col-doorlooptijd',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'bureau_status',
      name: 'Doorlooptijd in dagen (max.)',
      class: 'text-xs-right',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'bureau_score',
      name: '% Doorlooptijd binnen norm (gem.)',
      class: 'text-xs-right',
      postfix: '%',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'bureau_days_left',
      name: 'Resterend aantal dagen (min.)',
      class: 'text-xs-right',
      style: 'min-width: 110px !important;',
      visible: true,
    },
    {
      key: 'expected_delivery_at',
      name: 'Verwachte Oplevering',
      class: 'text-xs-right',
      style: 'min-width: 110px !important;',
      visible: true,
    },
  ];

  protected treeViewOptions: TreeViewOptions = {
    showStatusLabels: true,
  };

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.emitBreadcrumb();
    await this.getOrganizations();
    this.isLoading = false;
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.savedFilters[organization.id] = cloneDeep(this.filters);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
    }
  }

  protected applyFilters() {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:termijn-bewaking',
      body: this.filters,
    };

    const rpcClient = new Rpc();
    if (this.$store.state.isServiceOrganization) {
      rpcClient.dmz(this.activeOrganization);
    }

    rpcClient
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.$set(this.items, `${[this.activeOrganization]}`, response.data);
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Termijn bewaking' },
        ],
      });
  }

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
  }

  protected get isSupervisorView(): boolean {
    return ! this.$store.state.Auth.has_management_info && this.$store.state.Auth.supervisor_info && this.$store.state.Auth.supervisor_info.is_supervisor;
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }
}

interface RealtimeManagementStruct {
  id?: string;
  active: boolean;
  name: string;
  data: RealtimeManagementData;
  children: RealtimeManagementStruct[];
}

interface RealtimeManagementData {
  planned_reports: number;
  delivered_reports: number;
  first_time_right: number;
  doorlooptijd: number;
  doorlooptijd_percentage: number;
  costs: number;
  is_special: boolean;
  expected_delivery_at: string;
}

interface TermijnBewakingFilters {
  types: string[];
  departments: string[];
  actor: string;
  breakdown: string;
  workflows: string[];
  opname_variants: string[];
  management_info_classes: string[];
}
